import React, { useMemo } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { color, breakpoints, sizing, title, tabs, button, hideScroll, border, flex } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import TopTen from '../../components/shared/TopTen';
import { categoryLinksInterpolate, getFile } from '../../utils/system';

const Wrapper = styled('div')`
  p {
    margin-bottom: 1rem;
  }
`;

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  margin: 15px 0 0;
  width: 100%;
  ${title}
  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius4} ${border.borderRadius4} 0 0;
    img {
      width: 100%;
    }

    @media (max-width: ${breakpoints.md}) {
      border-radius: 4px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 15px;
    .breadcrumbs {
      position: static;
      margin: 10px 0;
    }
    .gatsby-image-wrapper {
      height: 100px;
    }
  }
`;

const Content = styled('div')`
  position: static;
  padding: 40px 50px 30px 10px;

  a {
    ${button};
    margin: 30px 0;
    width: 233px;
    height: 59px;
    border-radius: 8px;
    background-color: ${color.lightGreen};
    ${flex};

    &:hover {
      background-color: #009500;
    }

    &:active {
      background-color: #009000;
      opacity: 0.7;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  position: relative;
  border-radius: 0 0 8px 8px;
  background-color: ${color.darkPurple};
  padding: 24px 40px 5px;
  margin-top: calc(65px + 30px);

  @media (max-width: ${breakpoints.md}) {
    padding: 20px;
    margin-top: calc(44px + 20px);

    & .wrapper-tabs {
      top: -54px !important;
      & .tab-menu {
        margin-bottom: 0;
        & > li {
          padding: 0 0 0 0 !important;
          & > a {
            height: 44px !important;
            font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
            font-size: 14px !important;
            font-weight: normal !important;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
    }
  }
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }
  .wrapper-tabs {
    position: absolute;
    left: 0;
    top: -65px;
    width: 100%;
    overflow: hidden;
    height: max-content;

    .tab-menu {
      ${tabs};
      ${hideScroll};
      overflow-x: scroll;
      position: relative;
      animation-name: movetabs;
      animation-duration: 1s;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > li {
        margin-bottom: 0;
        border-radius: 0;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }

        & > a {
          border-radius: 4px 4px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 65px;

          &.active {
            background-color: ${color.darkPurple};
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .wrapper-tabs {
      height: 75px;
      top: -60px;
    }
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 15px 0 !important;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .wrapper-tabs {
      height: 75px;
      top: -63px;
    }
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: inline;
      color: ${color.blue};
      font-weight: ${sizing.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

const Sidebar = styled('div')`
  width: 268px;
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const PageWithTopTenComponent = ({ data, pageContext, img, imgName }) => {
  const { wordpressPage, allWordpressWpApiMenusMenusItems } = data;
  const { featured_media = {}, yoast_meta, title, content, path } = wordpressPage;
  const { nodes = [{}] } = allWordpressWpApiMenusMenusItems;
  const { items: menu = [] } = nodes[0];
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  const { related, breadcrumbs } = pageContext;
  const metaTitle = (yoast_meta && yoast_wpseo_title) || `${title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc = (yoast_meta && yoast_wpseo_metadesc) || '';
  const socialImage = featured_media?.localFile?.publicURL;

  const Result = useMemo(
    () => (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDesc}
          pathname={path}
          breadcrumbs={breadcrumbs}
          socialImage={socialImage}
        />
        <Wrapper>
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={title} />
            {getFile(featured_media) && <Img fluid={getFile(featured_media)} alt={featured_media.alt_text || title} />}
            <div className="top-title">
              {title}
              {img && <img className="iconImage" src={img} alt={imgName} />}
            </div>
          </FeaturedWrapper>
          {related.length && (
            <ContentWrapper>
              <div className="wrapper-tabs">
                <ul className="tab-menu" id="menu">
                  {menu?.map(({ type_label, object_slug, path, title }) => (
                    <li key={type_label + object_slug}>
                      <Link activeClassName="active" to={`${categoryLinksInterpolate(path)}#menu`}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <Content>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Content>
              <Sidebar>
                <TopTen />
              </Sidebar>
            </ContentWrapper>
          )}
        </Wrapper>
      </Layout>
    ),
    [img, title],
  );

  return Result;
};

export default PageWithTopTenComponent;

export const acf = graphql`
  fragment acf on wordpress__PAGEAcf {
    page_template
  }
`;
